import {FormEvent, useContext, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import { NarrowPage } from 'design-system'
import API from 'api'
import {AuthContext} from 'auth'
import {
  useQueryClient
} from '@tanstack/react-query'

const InvitationPage = () => {
  const {campaignId, invitationCode} = useParams()
  const {token, setToken} = useContext(AuthContext)
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const queryClient = useQueryClient()

  const save = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (name.length > 0) {
      API.claimInvitation(token.idToken!, {name, campaignId: campaignId!, invitationCode: invitationCode!})
        .then(campaign => {
          queryClient.invalidateQueries()
          navigate(`/campaigns/${campaign.id}/articles`)
        })
        .catch(e => {
          if (e.status === 401) {
            setToken({})
            navigate('/')
            return
          }
          console.error(e)
        })
    }
  }
  const change = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    setName(target.value.trim())
  }
  return (
    <NarrowPage>
      <h1>Join Campaign</h1>
      <p>You've been invited to join the campaign. Please provide a name other players can call you.</p>
      <form onSubmit={save}>
        <div className='mb-3 d-flex'>
          <label className='col-form-label me-3 text-nowrap'>Your Name</label>
          <input
            type='text'
            className='form-control'
            onChange={change}
          />
        </div>
        <div className='mb-3'>
          <button type='submit' className='btn btn-primary'>Join Campaign</button>
        </div>
      </form>
    </NarrowPage>
  )
}
export default InvitationPage
