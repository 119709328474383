import { FC, ReactNode, useContext } from 'react'
import AuthContext from './AuthContext'
import IfLoggedIn from './IfLoggedIn'

function buildCognitoUri(path: string): string {
  const domain = process.env.REACT_APP_COGNITO_DOMAIN
  const query = [
    `client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
    'response_type=token',
    'scope=email+openid+profile',
    `redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_DOMAIN}/auth/callback`
  ].join('&')
  return `${domain}${path}?${query}`
}

const logoutUri = buildCognitoUri('/logout')

type Props = {
  children: ReactNode,
  className?: string
}

const LogoutLink: FC<Props> = ({children, className}) => {
  const {setToken} = useContext(AuthContext);
  return (
    <IfLoggedIn>
      <a className={className} href={logoutUri} onClick={() => {setToken({})}}>{children}</a>
    </IfLoggedIn>
  )
}

export default LogoutLink
