import {FC} from 'react'
import { Link } from 'react-router-dom'
import {MapPin as ApiMapPin} from 'api'
import {MapPinIcon} from '@heroicons/react/20/solid'
import {useCampaign} from 'queries'
import styles from './MapPin.module.scss'
import {useArticlesTree} from 'queries'

type Props = {
  pin: ApiMapPin
}

const MapPin: FC<Props> = ({pin}) => {
  const campaign = useCampaign()
  const articleTree = useArticlesTree()
  const pinnedArticle = articleTree?.get(pin.pinnedArticleId)
  if (!pinnedArticle) {
    return <></>
  }
  return <Link to={`/campaigns/${campaign?.id}/articles/${pin.pinnedArticleId}`} className={styles.mapPin}>
    <div><span>{pinnedArticle.name}</span></div>
    <MapPinIcon/>
  </Link>
}

export default MapPin
