import { useParams, Outlet } from 'react-router-dom'
import {SidebarLayout} from 'design-system'
import {Articles} from 'articles'
import ArticlePins from './ArticlePins'

const ArticleSidebarLayout = () => {
  const {campaignId, articleId} = useParams()
  if (!campaignId) {
    return <></>
  }
  return (
    <SidebarLayout
      sidebar={<Articles/>}
      body={<><ArticlePins/><Outlet /></>}
      expansionMemo={articleId}
    />
  )
}

export default ArticleSidebarLayout
