import { useContext, useMemo } from 'react'
import {AuthContext} from 'auth'
import {CampaignSocketContext} from 'campaigns'
import {
  useQuery
} from '@tanstack/react-query'
import API, {ObjectReference} from 'api'
import { useNavigate, useParams } from 'react-router-dom'

function useAPIReferences() {
  const {token, setToken} = useContext(AuthContext)
  const navigate = useNavigate()
  const {campaignId} = useParams()

  return useQuery<ObjectReference[]>({
    queryKey: ['apiReferences', token?.idToken],
    queryFn: async () => {
      if (!token?.idToken) {
        return []
      }
      try {
        return await API.getReferences(token.idToken, campaignId!)
      }
      catch (e: any) {
        if (e.status === 401) {
          setToken({})
          navigate('/')
          return []
        }
        console.error(e)
      }
    }
  } as any)
}

function useLatestReferences(): Record<string, ObjectReference> {
  const {data: apiReferences} = useAPIReferences()
  const {latestSocketChangesByType} = useContext(CampaignSocketContext)
  const socketReferenceChanges = latestSocketChangesByType['Reference']
  return useMemo(() => {
    const latest: Record<string, ObjectReference> = {}
    for (const reference of (apiReferences || [])) {
      latest[reference.id] = reference
    }
    for (const change of Object.values(socketReferenceChanges || {})) {
      const apiMatch = latest[change.id]
      if (!apiMatch || Number(change.version) > Number(apiMatch.version)) {
        if (change.object) {
          latest[change.id] = change.object
        }
        else {
          delete latest[change.id]
        }
      }
    }
    return latest
  }, [apiReferences, socketReferenceChanges])
}

function useReferencesGroupedBySource(): Record<string, ObjectReference[]> {
  const latest = useLatestReferences()
  return useMemo(() => {
    const map: Record<string, ObjectReference[]> = {};
    for (const reference of Object.values(latest)) {
      map[reference.sourceId] ||= []
      map[reference.sourceId].push(reference)
    }
    return map
  }, [latest])
}

export function useReferencesFrom(sourceId?: string): ObjectReference[] {
  const groups = useReferencesGroupedBySource()
  return useMemo(() => {
    if (!sourceId) {
      return []
    }
    return groups[sourceId] || []
  }, [groups, sourceId])
}
