import {FC, useContext, ReactNode} from 'react'
import { Link } from 'react-router-dom'
import API, {Article} from 'api'
import {AuthContext} from 'auth'
import { useCampaign, useUpdateArticle } from 'queries'
import {ImageDropReceiver} from 'drag'
import Parchment from './Parchment'
import styles from './ArticleCard.module.scss'

type Props = {
  article: Article
  controls?: ReactNode
}

const ArticleCard: FC<Props> = ({article, controls}) => {
  const campaign = useCampaign()
  const {token, userId} = useContext(AuthContext)
  const {mutate: updateArticle} = useUpdateArticle()

  const isGamemaster = campaign?.writers.includes(userId!)
  const canEditArticle = isGamemaster || article!.writers.includes(userId!) || article!.writers.includes('*')

  const uploadImage = async (image: File) => {
    const upload = await API.upload(token.idToken!, campaign!.id, image)
    await updateArticle({heroImage: {
      id: upload.id,
      filename: upload.filename
    }});
  }

  const uploadImages = async (images: File[]) => {
    if (images.length > 1) {
      alert('Please drop only one image here.')
      return
    }
    if (images.length < 1) {
      return
    }
    await uploadImage(images[0])
  }

  return (
    <Link to={`/campaigns/${campaign?.id}/articles/${article.id}`} className={styles.link}>
    <Parchment className={styles.card}>
      <div className='d-flex'>
        <h2>{article!.name}</h2>
        <div className='ms-auto'>{controls}</div>
      </div>
      <ImageDropReceiver className={styles.heroImage} onDrop={uploadImages} image={article!.heroImage} disabled={!canEditArticle}/>
    </Parchment>
    </Link>
  )
}
export default ArticleCard
