import {FC, useState} from 'react'
import styles from './ArticleSearchField.module.scss'
import {MagnifyingGlassIcon, XMarkIcon} from '@heroicons/react/20/solid'
import {IconButton} from 'design-system'

type Props = {
  onChange?: (value: string) => void
}

const ArticleSearchField: FC<Props> = ({onChange}) => {
  const [search, setSearch] = useState("")
  return (
    <>
      <div>
        <input
          type='text'
          className={`form-control ${styles.searchField}`}
          onChange={(e) => {
            setSearch(e.target.value)
            if (onChange) {
              onChange(e.target.value)
            }
          }}
          value={search}
        />
        {search.length === 0 && <IconButton><MagnifyingGlassIcon/></IconButton>}
        {search.length > 0 && (<IconButton onClick={() => {
          setSearch('')
          if (onChange) {
            onChange('')
          }
        }}><XMarkIcon/></IconButton>)}
      </div>
    </>
  )
}

export default ArticleSearchField
