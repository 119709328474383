import {
  Article,
  ArticleUpdate,
  Campaign,
  MapItem,
  MapPin,
  Membership,
  ObjectReference,
  ObjectReferenceUpdate,
  Reference,
  UploadedFile
} from './types'
import {v4} from 'uuid'

class APIError extends Error {
  public readonly status: number
  public readonly body: any
  constructor(message: string, {status, body}: {status: number, body: any}) {
    super(message)
    this.name = "APIError"
    this.status = status
    this.body = body
  }
}

export default class Client {
  public static async getCampaigns(token: string): Promise<Campaign[]> {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return body.campaigns
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  public static async createCampaign(token: string, properties: {name: string, username: string}): Promise<Campaign> {
    const id = v4()
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify(properties)
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return body.campaign
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  public static async updateCampaign(token: string, id: string, properties: {name?: string, invitationCode?: string | null}): Promise<Campaign> {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify(properties)
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return body.campaign
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  public static async claimInvitation(token: string, properties: {name: string, campaignId: string, invitationCode: string}): Promise<Campaign> {
    const {name, campaignId, invitationCode} = properties
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaignId}/invitations/${invitationCode}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify({name})
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return body.campaign
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  public static async getMemberships(token: string, campaignId: string): Promise<Membership[]> {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaignId}/memberships`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return body.memberships
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  public static async getArticles(token: string, campaignId: string): Promise<Article[]> {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaignId}/articles`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return this.presentArticles(body.articles)
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  private static presentArticles(articles: any[]): Article[] {
    return articles.map(a => this.presentArticle(a))
  }

  private static presentArticle(article: any): Article {
    return {
      ...article,
      position: Number(article.position)
    }
  }

  public static async getReferences(token: string, campaignId: string): Promise<ObjectReference[]> {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaignId}/references`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return this.presentReferences(body.references)
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  private static presentReferences(references: any[]): ObjectReference[] {
    return references.map(a => this.presentReference(a))
  }

  private static presentReference(reference: any): ObjectReference {
    return {
      ...reference
    }
  }

  public static async upload(token: string, campaignId: string, file: File): Promise<UploadedFile> {
    const id = v4()
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaignId}/uploads/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify({filename: file.name})
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      await fetch(body.signedUrl, {method: 'PUT', body: file});
      return {
        id,
        filename: file.name
      }
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  public static async createArticle(
    token: string,
    campaignId: string,
    properties: {
      name: string,
      description?: string,
      position: number,
      parentItem?: Reference
    }
  ): Promise<Article> {
    const id = v4()
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaignId}/articles/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify(properties)
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return this.presentArticle(body.article)
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  public static async updateArticle(
    token: string,
    campaignId: string,
    articleId: string,
    properties: ArticleUpdate
  ): Promise<Article> {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaignId}/articles/${articleId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify(properties)
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return this.presentArticle(body.article)
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  public static async createReference(
    token: string,
    campaignId: string,
    properties: {
      sourceId: string,
      targetId: string
    }
  ): Promise<ObjectReference> {
    const id = v4()
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaignId}/references/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify(properties)
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return this.presentReference(body.reference)
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  public static async updateReference(
    token: string,
    campaignId: string,
    referenceId: string,
    properties: ObjectReferenceUpdate
  ): Promise<ObjectReference> {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaignId}/references/${referenceId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify(properties)
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return this.presentReference(body.reference)
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  public static async deleteReference(
    token: string,
    campaignId: string,
    referenceId: string
  ): Promise<void> {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaignId}/references/${referenceId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'delete'
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  public static async getMapItems(token: string, campaign: Campaign, article: Article): Promise<MapItem[]> {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaign.id}/articles/${article.id}/map-items`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return this.presentMapItems(body.mapItems)
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

  private static presentMapItems(mapItems: any[]): MapItem[] {
    return mapItems.map(mi => this.presentMapItem(mi))
  }

  private static presentMapItem(mapItem: any): MapItem {
    return {
      ...mapItem,
      x: Number(mapItem.x),
      y: Number(mapItem.y)
    }
  }

  private static presentMapPin(mapPin: any): MapPin {
    return {
      ...mapPin
    }
  }

  public static async createMapPin(
    token: string,
    campaignId: string,
    articleId: string,
    properties: {
      x: number,
      y: number,
      pinnedArticleId: string
    }
  ): Promise<MapPin> {
    const id = v4()
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/campaigns/${campaignId}/articles/${articleId}/map-pins/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify(properties)
    })
    const body = await response.json()
    if (response.status >= 200 && response.status < 300) {
      return this.presentMapPin(body.mapPin)
    }
    else {
      throw new APIError(body.message, {status: response.status, body: body})
    }
  }

}
