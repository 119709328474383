import { Outlet } from "react-router-dom"
import PlotWeaveMenu from './PlotWeaveMenu'

const Layout = () => {
  return (
    <div>
      <PlotWeaveMenu/>
      <Outlet />
    </div>
  )
}

export default Layout
