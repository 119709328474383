import {FC, ReactNode} from 'react'
import styles from './Page.module.scss'

type Props = {
  children: ReactNode
}

const Page: FC<Props> = ({children}) => {
  return (
    <div className={styles.page}>
      {children}
    </div>
  )
}

export default Page
