import { NarrowPage } from 'design-system'
import {LoginLink, IfLoggedIn} from 'auth'
import {CampaignTiles} from 'campaigns'

const HomePage = () => {
  return (
    <NarrowPage>
      <img src="/images/medium-square-logo.jpg" alt="Plot Weave logo"/>
      <p className='lead text-muted'>Bring your story to life.</p>
      <LoginLink className='btn btn-primary'>Get Started</LoginLink>
      <IfLoggedIn>
        <CampaignTiles/>
      </IfLoggedIn>
    </NarrowPage>
  )
}
export default HomePage
