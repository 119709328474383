import {FC, ReactNode} from 'react'
import { Link } from 'react-router-dom'
import {Article} from 'api'
import { useCampaign } from 'queries'
import {UploadedImage} from 'app'
import styles from './ArticleImage.module.scss'

type Props = {
  article: Article
  controls?: ReactNode
}

const ArticleImage: FC<Props> = ({article, controls}) => {
  const campaign = useCampaign()

  return (
    <Link to={`/campaigns/${campaign?.id}/articles/${article.id}`} className={styles.link}>
      <div className={styles.card}>
        <UploadedImage image={article!.heroImage} />
        <div className={styles.label}>
          <div className={styles.name}>
            {article!.name}
          </div>
          {controls && <div className={styles.controls}>{controls}</div>}
        </div>
      </div>
    </Link>
  )
}
export default ArticleImage
