import ArticlePin from './ArticlePin'
import PinnedMap from './PinnedMap'
import {usePinnedArticles} from 'queries'
import ArticleContextProvider from './ArticleContextProvider'
import styles from './ArticlePins.module.scss'

const ArticlePins = () => {
  const pinnedArticles = usePinnedArticles()
  return (
    <>
      <div className='d-flex overflow-scroll justify-content-center'>
        {pinnedArticles?.filter(pa => pa.articleType !== 'Map' && pa.articleType !== 'Scene' && pa.articleType !== 'Player Character').map(a =>
          <ArticleContextProvider articleId={a.id} key={a.id}>
            <ArticlePin article={a} />
          </ArticleContextProvider>
        )}

        {pinnedArticles?.filter(pa => pa.articleType === 'Map').map(a =>
          <div className={styles.pinnedMap} key={a.id}>
            <ArticleContextProvider articleId={a.id} key={a.id}>
              <PinnedMap article={a} />
            </ArticleContextProvider>
          </div>
        )}
      </div>
    </>
  )
}

export default ArticlePins
