import { DragEvent, FC, useContext, useRef } from 'react'
import {Link} from 'react-router-dom'
import {DropReceiver} from 'drag'
import API from 'api'
import {AuthContext} from 'auth'
import {useArticle, useCampaign, useUpdateArticle} from 'queries'
import {UploadedImage} from 'app'
import MapContext from './MapContext'
import MapItem from './MapItem'
import useMapApi from './useMapApi'
import styles from './Map.module.scss'

type Props = {
  className?: string
  href?: string
}

const Map: FC<Props> = ({className, href}) => {
  const article = useArticle()
  const {token, userId} = useContext(AuthContext)
  const campaign = useCampaign()
  const {mutate: updateArticle} = useUpdateArticle()
  const {createMapPin} = useMapApi()
  const isGamemaster = campaign?.writers.includes(userId!)
  const imageRef = useRef<HTMLDivElement>(null)
  const {mapItems} = useContext(MapContext)

  const uploadImage = async (image: File) => {
    const upload = await API.upload(token.idToken!, campaign!.id, image)
    updateArticle({heroImage: {
      id: upload.id,
      filename: upload.filename
    }})
  }

  const uploadImages = async (images: File[]) => {
    if (images.length > 1) {
      alert('Please drop only one image here.')
      return
    }
    if (images.length < 1) {
      return
    }
    await uploadImage(images[0])
  }

  const internalOnDrop = async (dataTransfer: DataTransfer, e: DragEvent) => {
    const storedDrag = localStorage.getItem('drag')
    if (storedDrag) {
      const parsed = JSON.parse(storedDrag)
      if (parsed.articleId && imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect()
        const x = (e.clientX - rect.left) / rect.width
        const y = (e.clientY - rect.top) / rect.height
        createMapPin({x, y, pinnedArticleId: parsed.articleId})
      }
      return
    }

    if (dataTransfer.files.length > 0) {
      uploadImages([...dataTransfer.files])
      return
    }
    const url = dataTransfer.getData('URL')
    if (url) {
      const response = await fetch(url)
      if (response.body) {
        const urlSegments = (url.split('?')[0]).split('/')
        const filename = urlSegments[urlSegments.length - 1]
        const file = new File([await response.blob()], filename)
        uploadImages([file])
      }
      return
    }
  }
  return (
    <div className={`${className} ${styles.flexContainer}`}>
      <div ref={imageRef} className={styles.mapContainer}>
        <DropReceiver onDrop={internalOnDrop} disabled={!isGamemaster}>
          {href && (<Link to={href}>
            <UploadedImage image={article!.heroImage} />
          </Link>)}
          {!href && <UploadedImage image={article!.heroImage} />}
        </DropReceiver>
        {imageRef.current && mapItems.map(i => <MapItem
          key={i.id}
          item={i}
          containerWidth={imageRef.current!.getBoundingClientRect().width}
          containerHeight={imageRef.current!.getBoundingClientRect().height}
        />)}
      </div>
    </div>
  )
}
export default Map
