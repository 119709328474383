import { useContext, useMemo } from 'react'
import {AuthContext} from 'auth'
import {CampaignSocketContext} from 'campaigns'
import {
  useQuery
} from '@tanstack/react-query'
import API, {Article} from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import {ArticleContext, PositionTree} from 'articles'

function useAPIArticles() {
  const {token, setToken} = useContext(AuthContext)
  const navigate = useNavigate()
  const {campaignId} = useParams()

  return useQuery<Article[]>({
    queryKey: ['apiArticles', token?.idToken],
    queryFn: async () => {
      if (!token?.idToken) {
        return []
      }
      try {
        return await API.getArticles(token.idToken, campaignId!)
      }
      catch (e: any) {
        if (e.status === 401) {
          setToken({})
          navigate('/')
          return []
        }
        console.error(e)
      }
    }
  } as any)
}

export function useArticlesMap(): Record<string, Article> {
  const {data: apiArticles} = useAPIArticles()
  const {latestSocketChangesByType} = useContext(CampaignSocketContext)
  const socketArticleChanges = latestSocketChangesByType['Article']
  return useMemo(() => {
    const latest: Record<string, Article> = {}
    for (const article of (apiArticles || [])) {
      latest[article.id] = article
    }
    for (const change of Object.values(socketArticleChanges || {})) {
      const apiMatch = latest[change.id]
      if (!apiMatch || Number(change.version) > Number(apiMatch.version)) {
        latest[change.id] = change.object
      }
    }
    return latest
  }, [apiArticles, socketArticleChanges])
}

export function useArticles(): Article[] {
  const latest = useArticlesMap()
  return useMemo(() => {
    return Object.values(latest)
  }, [latest])
}

export function useArticle(id?: string): Article | undefined {
  const {articleId} = useContext(ArticleContext)
  id = id || articleId;
  const latest = useArticlesMap()
  if (!id) {
    return
  }
  return latest[id];
}

export function useArticlesTree() {
  const articles = useArticles()
  return useMemo(() => {
    return new PositionTree<Article>(articles)
  }, [articles])
}
