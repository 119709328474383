import { FC, ReactNode, useContext } from 'react'
import AuthContext from './AuthContext'

type Props = {
  children: ReactNode,
}

const IfLoggedOut: FC<Props> = ({children}) => {
  const {token} = useContext(AuthContext);
  return <>{!token.idToken && children}</>
}

export default IfLoggedOut
