import {FC} from 'react'
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import IconButton from './IconButton'

type Props = {
  onClick: (newValue: boolean) => void,
  muted?: boolean,
  showOnHover?: boolean,
  expanded: boolean,
  enabled?: boolean
}

const ExpandButton: FC<Props> = ({enabled, expanded, muted, showOnHover, onClick}) => {
  enabled = enabled === undefined ? true : enabled
  return (
    <IconButton onClick={() => onClick(!expanded)} showOnHover={showOnHover} muted={muted} enabled={enabled}>
      {expanded && <ChevronDownIcon/>}
      {!expanded && <ChevronRightIcon/>}
    </IconButton>
  )
}

export default ExpandButton
