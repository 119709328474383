import {FC, useEffect, useRef, useState} from 'react'
import {IconButton} from 'design-system'
import { RiMovie2Fill } from "react-icons/ri";
import { HiDocumentText, HiGift, HiMap, HiUser } from "react-icons/hi";
import {ArticleType} from 'api'
import styles from './ArticleTypeControl.module.scss'

type Props = {
  type: ArticleType
  onChange: (value: ArticleType) => void
  disabled: boolean
}

const ArticleTypeControl: FC<Props> = ({type, onChange, disabled}) => {
  const ref = useRef(null)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !(ref.current as Element).contains(event.target as Node)) {
        setExpanded(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    };
  }, []);

  return (
    <span ref={ref} className='position-relative'>
      <span className='pt-1'>
        <IconButton onClick={() => {if (!disabled) setExpanded(!expanded)}}>
          {type==="Document" && <HiDocumentText/>}
          {type==="Map" && <HiMap/>}
          {type==="Item" && <HiGift/>}
          {type==="Character" && <HiUser/>}
          {type==="Player Character" && <HiUser/>}
          {type==="Scene" && <RiMovie2Fill/>}
        </IconButton>
      </span>
      {expanded && (
        <span className=''>
          <div className={`${styles.flyover}`}>
            <IconButton onClick={() => {setExpanded(false); onChange('Document')}}>
              <HiDocumentText/> Document
            </IconButton>
            <IconButton onClick={() => {setExpanded(false); onChange('Item')}}>
              <HiGift/> Item
            </IconButton>
            <IconButton onClick={() => {setExpanded(false); onChange('Map')}}>
              <HiMap/> Map
            </IconButton>
            <IconButton onClick={() => {setExpanded(false); onChange('Character')}}>
              <HiUser/> Character
            </IconButton>
            <IconButton onClick={() => {setExpanded(false); onChange('Player Character')}}>
              <HiUser/> Player Character
            </IconButton>
            <IconButton onClick={() => {setExpanded(false); onChange('Scene')}}>
              <RiMovie2Fill/> Scene
            </IconButton>
          </div>
        </span>
      )}
    </span>
  )
}
export default ArticleTypeControl
