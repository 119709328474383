import {FC, useEffect, useRef, useState} from 'react'
import {IconButton, LabeledCheckbox} from 'design-system'
import {HiEye, HiEyeOff} from 'react-icons/hi'
import {Membership} from 'api'
import styles from './PrivacyControl.module.scss'
import {useMemberships} from 'queries'


type Props = {
  readers: string[],
  writers: string[],
  inheritedReaders: string[],
  inheritedWriters: string[],
  onChange: (readers: string[], writers: string[]) => void,
  userId: string,
  className?: string
}

const PrivacyControl: FC<Props> = (props) => {
  const memberships = useMemberships()
  const ref = useRef(null)
  const [expanded, setExpanded] = useState(false)
  const [readers, setReaders] = useState(props.readers)
  const [writers, setWriters] = useState(props.writers)
  useEffect(() => {
    setReaders(props.readers)
    setWriters(props.writers)
  }, [props.readers, props.writers])

  const isReader = (m: Membership): boolean => {
    return readers.includes(m.userId)
  }

  const isWriter = (m: Membership): boolean => {
    return writers.includes(m.userId)
  }

  const setReader = (userId: string, value: boolean) => {
    if (value && !readers.includes(userId)) {
      const newValue = [...readers, userId]
      setReaders(newValue)
      props.onChange(newValue, writers)
    }
    if (!value && readers.includes(userId)) {
      const newValue = readers.filter(r => r !== userId)
      setReaders(newValue)
      let newWriters = writers
      if (writers.includes(userId)) {
        newWriters = writers.filter(r => r !== userId)
        setWriters(newWriters)
      }
      props.onChange(newValue, newWriters)
    }
  }

  const setWriter = (userId: string, value: boolean) => {
    if (value && !writers.includes(userId)) {
      const newValue = [...writers, userId]
      setWriters(newValue)
      let newReaders = readers
      if (!readers.includes(userId)) {
        newReaders = [...readers, userId]
        setReaders(newReaders)
      }
      props.onChange(newReaders, newValue)
    }
    if (!value && writers.includes(userId)) {
      const newValue = writers.filter(r => r !== userId)
      setWriters(newValue)
      props.onChange(readers, newValue)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !(ref.current as Element).contains(event.target as Node)) {
        setExpanded(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    };
  }, []);

  return (
    <span ref={ref}>
      <span className={`pt-1 ${props.className}`}>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {readers.includes('*') ? <HiEye/> : <HiEyeOff/>}
        </IconButton>
      </span>
      {expanded && memberships && (
        <span className='position-relative'>
          <div className={`position-absolute ${styles.flyover}`}>
            <div className='d-flex'>
              Seen by:
              <LabeledCheckbox
                className="ms-auto"
                label="Everyone"
                checked={props.inheritedReaders.includes('*') || readers.includes("*")}
                onChange={v => setReader('*', v)}
              />
            </div>
            {memberships.map(m => {
              const isInherited = props.inheritedReaders.includes(m.userId) || props.inheritedReaders.includes('*')
              return <LabeledCheckbox
                label={m.name}
                key={`seen-by-${m.userId}`}
                checked={isInherited || readers.includes('*') || isReader(m)}
                disabled={isInherited || readers.includes('*') || props.userId === m.userId}
                onChange={v => setReader(m.userId, v)}
              />
            })}
            <div className='d-flex'>
              Edited by:
              <LabeledCheckbox
                className="ms-auto"
                label="Everyone"
                checked={props.inheritedWriters.includes('*') || writers.includes("*")}
                disabled={props.inheritedWriters.includes('*')}
                onChange={v => setWriter('*', v)}
              />
            </div>
            {memberships.map(m => {
              const isInherited = props.inheritedWriters.includes(m.userId) || props.inheritedWriters.includes('*')
              return <LabeledCheckbox
                label={m.name}
                key={`edited-by-${m.userId}`}
                checked={isInherited || writers.includes('*') || isWriter(m)}
                disabled={isInherited || writers.includes('*') || props.userId === m.userId}
                onChange={v => setWriter(m.userId, v)}
              />
            })}
          </div>
        </span>
      )}
    </span>
  )
}
export default PrivacyControl
