import { FC } from 'react'
import { Link } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/20/solid'
import { LoadingIndicator } from 'design-system'
import {useCampaigns} from 'queries'

const CampaignTiles: FC<{}> = () => {
  const {data: campaigns, isLoading: loading} = useCampaigns()

  if (loading) {
    return <LoadingIndicator />
  }
  return <>
    {campaigns?.map((campaign, index) => (
      <div key={campaign.id} className='my-3'>
        <Link to={`/campaigns/${campaign.id}/articles`}>
          {campaign.name}
        </Link>
      </div>
    ))}
    <Link to="/campaigns/new" className='btn btn-secondary mt-3'>
      <PlusIcon/> Start a new Campaign
    </Link>
  </>
}

export default CampaignTiles
