import { FC, ReactNode, useState } from 'react'
import {useInterval} from 'react-extensions'
import DragContext from './DragContext'

type Props = {
  children: ReactNode
}

const DragContextProvider: FC<Props> = ({children}) => {
  const [dragging, setDragging] = useState<boolean>(false)
  const [mostRecentlyDraggedOverElement, setMostRecentlyDraggedOverElement] = useState<Element | null>(null)
  const [lastDragDate, setLastDragDate] = useState<Date | null> (null)

  useInterval(() => {
    if (dragging && (!lastDragDate || (new Date()).getTime() - lastDragDate.getTime() > 500)) {
      setDragging(false)
    }
  }, 100)
  return (
    <DragContext.Provider value={{
      dragging,
      mostRecentlyDraggedOverElement,
      setMostRecentlyDraggedOverElement: (element: Element | null) => {
        console.log('setMostRecentlyDraggedOverElement')
        setDragging(true)
        setMostRecentlyDraggedOverElement(element)
        setLastDragDate(new Date())
      }
    }}>
      <div onDragOver={() => {
        setDragging(true)
        setMostRecentlyDraggedOverElement(null)
        setLastDragDate(new Date())
      }}>
        {children}
      </div>
    </DragContext.Provider>
  )
}

export default DragContextProvider
