import { FC } from 'react'
import ReferenceImage from './ReferenceImage'
import { useArticle, useReferencesFrom } from 'queries'
import styles from './ArticleReferenceList.module.scss'

const ArticleReferenceList: FC = () => {
  const article = useArticle()
  const references = useReferencesFrom(article?.id)
  return (
    <div className={styles.container}>
      {references.map(r =>
        <ReferenceImage reference={r} key={r.id}/>
      )}
      {references.length > 0 && <div className={styles.placeholder}/>}
    </div>
  )
}

export default ArticleReferenceList
