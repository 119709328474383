import {FC, ReactNode} from 'react'
import {Article} from 'api'
import {useCampaign} from 'queries'
import MapContextProvider from './MapContextProvider'
import Map from './Map'
import styles from './MapCard.module.scss'

type Props = {
  article: Article
  controls?: ReactNode
}

const MapCard: FC<Props> = ({article, controls}) => {
  const campaign = useCampaign()

  return (
    <MapContextProvider>
      <div className={styles.card}>
        <div className={styles.controlsBox}>{controls}</div>
        <Map className={styles.map} href={`/campaigns/${campaign?.id}/articles/${article.id}`}/>
      </div>
    </MapContextProvider>
  )
}
export default MapCard
