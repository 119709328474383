import { useContext } from 'react'
import {AuthContext} from 'auth'
import {
  useQuery
} from '@tanstack/react-query'
import API, {Campaign} from 'api'
import { useNavigate } from 'react-router-dom'

export default function useCampaigns() {
	const {token, setToken} = useContext(AuthContext)
  const navigate = useNavigate()

	return useQuery<Campaign[]>({ 
    queryKey: ['campaigns', token?.idToken], 
    queryFn: async () => {
      if (!token?.idToken) {
        return []
      }
      try {
        return await API.getCampaigns(token.idToken)
      }
      catch (e: any) {
        if (e.status === 401) {
          setToken({})
          navigate('/')
          return []
        }
        console.error(e)
      }
    },
    refetchInterval: 1000
  } as any)
}
