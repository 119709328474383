import {FC, useContext} from 'react'
import {IconButton} from 'design-system'
import {BsPinFill} from 'react-icons/bs'
import {Article, ArticleUpdate} from 'api'
import {AuthContext} from 'auth'
import {useCampaign, usePinnedArticles, useUpdateArticle} from 'queries'

type Props = {
  article: Article
}

const ArticlePinControl: FC<Props> = ({article}) => {
  const campaign = useCampaign()
  const {userId} = useContext(AuthContext)
  const {mutate: updateArticle} = useUpdateArticle()
  const pinnedArticles = usePinnedArticles()

  const pinArticle = async () => {
    if (article.articleType === 'Scene') {
      for (const article of pinnedArticles) {
        if (article.articleType === 'Scene') {
          updateArticle({articleId: article.id, pinned: false})
        }
      }
    }
    const update: ArticleUpdate = {pinned: !article.pinned}
    if (!article.pinned && !article.readers.includes('*')) {
      update.readers = ['*', ...article.readers]
    }
    updateArticle(update)
  }

  const isGamemaster = campaign?.writers.includes(userId!)
  if (isGamemaster && (article.articleType === 'Scene' || !article!.pinned)) {
    return <IconButton onClick={pinArticle}><BsPinFill/></IconButton>
  }
  return null
}

export default ArticlePinControl
