import {FC, ReactNode, useEffect, useState} from 'react'

type Props = {
  label: ReactNode
  checked: boolean
  disabled?: boolean
  onChange?: (checked: boolean) => void
  className?: string
}

const LabeledCheckbox: FC<Props> = (props) => {
  const [checked, setChecked] = useState(props.checked)
  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])
  const toggle = () => {
    if (props.disabled) {
      return
    }
    setChecked(!checked)
    if (props.onChange) {
      props.onChange(!checked)
    }
  }
  return (
    <div className={`form-check ${props.className}`}>
      <input type='checkbox' className='form-check-input' checked={checked} disabled={props.disabled} onChange={toggle}/>
      <label className='form-check-label' onClick={toggle}>{props.label}</label>
    </div>
  )
}
export default LabeledCheckbox
