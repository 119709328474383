import { FC, ReactNode } from 'react'
import ArticleContext from './ArticleContext'

type Props = {
  children: ReactNode
  articleId: string
}

const ArticleContextProvider: FC<Props> = ({children, articleId}) => {
  return (
    <ArticleContext.Provider value={{articleId}}>
      {children}
    </ArticleContext.Provider>
  )
}

export default ArticleContextProvider
