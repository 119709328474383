import { DragEvent, FC, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {ExpandButton} from 'design-system'
import { Article } from 'api'
import { useArticlesTree, useCampaign } from 'queries'
import ArticleInsertionControl from './ArticleInsertionControl'
import ArticleList from './ArticleList'

type Props = {
  article: Article,
  parent?: Article,
  previousPosition?: number
}

const ArticleListItem: FC<Props> = ({article, previousPosition, parent}) => {
  const campaign = useCampaign()
  const articleTree = useArticlesTree()
  const children = articleTree?.childrenOf(article) || []
  const [expanded, setExpanded] = useState<boolean | null>(null)
  const actualExpanded = expanded === null ? children.length > 0 : expanded
  const draggableElement = useRef(null)

  const startDrag = (e: DragEvent) => {
    e.stopPropagation()
    localStorage.setItem("drag", JSON.stringify({
      articleId: article.id,
      html: (draggableElement.current as Element | null)?.outerHTML
    }))
    e.dataTransfer.setData("articleId", article.id)
  }
  const endDrag = (e: DragEvent) => {
    e.stopPropagation()
    localStorage.removeItem("drag")
  }

  return (
    <div>
      {campaign?.isGamemaster &&
        <div className='ms-3'>
          <ArticleInsertionControl gt={previousPosition} lt={article.position} parent={parent}/>
        </div>
      }
      <div draggable={campaign?.isGamemaster} onDragStart={startDrag} onDragEnd={endDrag} ref={draggableElement}>
        <div>
          <ExpandButton onClick={setExpanded} muted={children.length === 0} showOnHover={children.length === 0} expanded={actualExpanded} enabled={campaign?.isGamemaster || children.length > 0}/>
          <Link to={`/campaigns/${campaign?.id}/articles/${article.id}`}>
            {article.name}
          </Link>
        </div>
        {actualExpanded && (
          <div key={article.id} className='ms-3'>
            <ArticleList parent={article}/>
          </div>
        )}
      </div>
    </div>
  )
}

export default ArticleListItem
