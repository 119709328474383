import {
  BlockNoteEditor,
  BlockSchema,
  PartialBlock,
} from "@blocknote/core";
import {
  ToolbarButton
} from '@blocknote/react'
import { useCallback, useMemo } from "react";
import { IconType } from "react-icons";
import {
  RiEyeLine,
  RiEyeOffLine
} from "react-icons/ri";

const icons: Record<string, IconType> = {
  '*': RiEyeLine,
  default: RiEyeOffLine
};

export const PrivacyFormattingToolbarButton = <BSchema extends BlockSchema>(props: {
  editor: BlockNoteEditor<BSchema>;
  backgroundColor: string;
}) => {
  const show = useMemo(() => {
    const selection = props.editor.getSelection();

    if (selection) {
      for (const block of selection.blocks) {
        if (!("backgroundColor" in block.props)) {
          return false;
        }
      }
    } else {
      const block = props.editor.getTextCursorPosition().block;

      if (!("backgroundColor" in block.props)) {
        return false;
      }
    }

    return true;
  }, [props.editor]);

  const setBackgroundColor = useCallback(
    (backgroundColor: string) => {
      props.editor.focus();

      const selection = props.editor.getSelection();

      if (selection) {
        for (const block of selection.blocks) {
          props.editor.updateBlock(block, {
            props: { backgroundColor },
          } as PartialBlock<BSchema>);
        }
      } else {
        const block = props.editor.getTextCursorPosition().block;

        props.editor.updateBlock(block, {
          props: { backgroundColor },
        } as PartialBlock<BSchema>);
      }
    },
    [props.editor]
  );

  if (!show) {
    return null;
  }

  return (
    <ToolbarButton
      onClick={() => setBackgroundColor(props.backgroundColor)}
      isSelected={
        props.editor.getTextCursorPosition().block.props.backgroundColor ===
        props.backgroundColor
      }
      mainTooltip={
        props.backgroundColor === "*"
          ? "Reveal"
          : "Hide "
      }
      icon={icons[props.backgroundColor]}
    />
  );
};
