import {useContext} from 'react'
import { useParams, Outlet } from 'react-router-dom'
import {Page} from 'design-system'
import CampaignPageHeader from './CampaignPageHeader'
import CampaignSocketContextProvider from './CampaignSocketContextProvider'
import {PinnedScene} from 'scenes'
import {AuthContext} from 'auth'

const CampaignPage = () => {
  const {campaignId} = useParams()
  const {userId} = useContext(AuthContext)
  if (!campaignId || !userId) {
    return <></>
  }
  return (
    <CampaignSocketContextProvider>
      <Page>
        <CampaignPageHeader/>
        <PinnedScene/>
        <Outlet />
      </Page>
    </CampaignSocketContextProvider>
  )
}

export default CampaignPage
