import {useState, useEffect} from 'react'
import ArticleList from './ArticleList'
import {Article} from 'api'
import ArticleSearchField from './ArticleSearchField'
import ArticleSearchResult from './ArticleSearchResult'
import Fuse from 'fuse.js'
import {useArticlesTree} from 'queries'

const fuseOptions = {
  keys: ['name', 'description', 'rawBody']
}

function blocksToBody(blocks: any[]): string {
  return blocks.map(b => b.content.map((c: any) => c.text).join(" ") + blocksToBody(b.children)).join(" ")
}

const Articles = () => {
  const [search, setSearch] = useState("")
  const [fuse, setFuse] = useState(new Fuse<Article>([], fuseOptions))
  const [results, setResults] = useState<{item: Article}[]>([])
  const articles = useArticlesTree()
  const articlesJson = articles ? JSON.stringify(articles?.flatFilter(() => true)) : undefined

  useEffect(() => {
    const articles = articlesJson ? JSON.parse(articlesJson) : undefined
    if (articles) {
      for (const article of articles) {
        article.rawBody = blocksToBody(article.bodyBlocks)
      }
      setFuse(new Fuse(articles, fuseOptions))
    }
  }, [articlesJson])

  useEffect(() => {
    setResults(fuse.search(search))
  }, [fuse, search])
  return (
    <>
      <ArticleSearchField onChange={s => setSearch(s)}/>
      {search.length > 0 && results.map(r => <ArticleSearchResult result={r} key={r.item.id}/>)}
      {search.length === 0 && <ArticleList/>}
    </>
  )
}

export default Articles
