import {Reference} from 'api'

interface Item {
  id: string
  position: number
  version: number
  parentItem?: Reference
}

interface ItemNode<T> {
  item: T
  children: ItemNode<T>[]
}

interface Node<T> {
  children: ItemNode<T>[]
}

type ItemMap<T> = {[key: string]: ItemNode<T>}

export default class PositionTree<T extends Item> {
  private readonly nodes: ItemMap<T> = {}
  private readonly root: Node<T> = { children: [] }

  constructor(items: T[] = []) {
    this.bulkSet(items)
  }

  set(item: T): boolean {
    if (this.nodes[item.id]) {
      return this.update(item)
    }
    else {
      this.add(item)
      return true
    }
  }

  get(id: string): T {
    return this.nodes[id]?.item
  }

  flatFilter(filter: (item: T) => boolean, root: Node<T> = this.root): T[] {
    const items = []
    for (const child of root.children) {
      if (filter(child.item)) {
        items.push(child.item)
      }
      items.push(...this.flatFilter(filter, child))
    }
    return items
  }

  getBreadcrumbs(id: string): T[] {
    const node = this.nodes[id]
    if (!node) {
      return []
    }
    return this.getBreadcrumbNodes(node).map(node => node.item)
  }

  private getBreadcrumbNodes(node: ItemNode<T>): ItemNode<T>[] {
    const parent = this.parentOf(node)
    if (parent && (parent as ItemNode<T>).item) {
      const nodes = this.getBreadcrumbNodes(parent as ItemNode<T>)
      nodes.push(parent as ItemNode<T>)
      return nodes
    }
    else {
      return []
    }
  }

  private parentOf(node: ItemNode<T>): Node<T> | null {
    if (!node.item?.parentItem) {
      return null
    }
    return this.nodes[node.item?.parentItem.id]
  }

  bulkSet(items: T[]): boolean {
    const addedNodes: ItemNode<T>[] = []
    for (const item of items) {
      const oldNode = this.nodes[item.id]
      if (!oldNode || oldNode.item.version < item.version) {
        if (oldNode) {
          const oldParent = this.getNodeOrRoot(oldNode.item.parentItem)
          this.removeNodeChild(oldParent, oldNode)
          oldNode.item = item
          addedNodes.push(oldNode)
        }
        else {
          addedNodes.push(this.addItemToNodes(item))
        }
      }
    }
    const parentNodes = addedNodes.map(node => this.addNodeToParentByReference(node, node.item.parentItem))
    const uniqueParentNodes = new Set(parentNodes)
    for (const parentNode of uniqueParentNodes) {
      this.sortNode(parentNode)
    }
    return addedNodes.length > 0
  }

  get rootItems(): T[] {
    return this.root.children.map(node => node.item)
  }

  get length(): number {
    return Object.entries(this.nodes).length
  }

  childrenOf(parent: T): T[] {
    if (this.nodes[parent.id]) {
      return this.nodes[parent.id].children.map(node => node.item)
    }
    console.error(`parent node ${parent.id} does not exist`)
    return []
  }

  private update(item: T): boolean {
    const node = this.nodes[item.id]
    const oldItem = node.item
    if (item.version <= oldItem.version) {
      return false;
    }
    const oldParent = this.getNodeOrRoot(node.item.parentItem)
    const newParent = this.getNodeOrRoot(item.parentItem)
    if (oldParent !== newParent) {
      this.removeNodeChild(oldParent, node)
      newParent.children.push(node)
    }
    node.item = item
    if (oldItem.position !== item.position) {
      this.sortNode(newParent)
    }
    return true
  }

  private removeNodeChild(parent: Node<T>, childToRemove: Node<T>) {
    parent.children = parent.children.filter(childNode => childNode !== childToRemove)
  }

  private add(item: T) {
    const node = this.addItemToNodes(item)
    const parentNode = this.addNodeToParentByReference(node, item.parentItem)
    this.sortNode(parentNode)
  }

  private addNodeToParentByReference(node: ItemNode<T>, parent?: Reference): Node<T> {
    const parentNode = this.getNodeOrRoot(parent)
    parentNode.children.push(node)
    return parentNode
  }

  private addItemToNodes(item: T): ItemNode<T> {
    const node = {
      item,
      children: []
    }
    this.nodes[item.id] = node
    return node;
  }

  private getNodeOrRoot(reference?: Reference): Node<T> {
    if (reference && this.nodes[reference.id]) {
      return this.nodes[reference.id]
    }
    return this.root
  }

  private sortNode(node: Node<T>) {
    node.children.sort((a, b) => a.item.position - b.item.position)
  }

  itemIsWithin(id: string, otherId: string) {
    if (id === otherId) {
      return true
    }
    let node = this.nodes[id]
    let parent = this.parentOf(node) as ItemNode<T>
    while (parent) {
      if (parent.item?.id === otherId) {
        return true
      }
      node = parent
      parent = this.parentOf(node) as ItemNode<T>
    }
    return false
  }
}
