import {FC, MouseEvent, ReactNode, useContext} from 'react'
import {ObjectReference} from 'api'
import {AuthContext} from 'auth'
import { useArticlesMap, useCampaign, useDeleteReference, useUpdateArticle, useUpdateReference } from 'queries'
import ArticleImage from './ArticleImage'
import {XMarkIcon } from '@heroicons/react/20/solid'
import {HiEye, HiEyeOff} from 'react-icons/hi'
import {FiShare} from 'react-icons/fi'
import {IconButton} from 'design-system'
import styles from './ReferenceImage.module.scss'

type Props = {
  reference: ObjectReference
  controls?: ReactNode[]
}

const ReferenceImage: FC<Props> = ({reference, controls}) => {
  const articlesMap = useArticlesMap()
  const {mutate: deleteReference} = useDeleteReference()
  const campaign = useCampaign()
  const {userId} = useContext(AuthContext)
  const isGamemaster = campaign?.writers.includes(userId!)
  const visible = reference.readers.includes('*')
  const {mutate: updateReference} = useUpdateReference();
  const {mutate: updateArticle} = useUpdateArticle();
  const article = articlesMap[reference.targetId];

  const onDeleteClick = async (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    await deleteReference({referenceId: reference.id})
  }

  const toggleVisible = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (!visible && !article.readers.includes('*')) {
      updateArticle({
        articleId: article.id,
        readers: ['*', ...article.readers]
      })
    }
    updateReference({
      referenceId: reference.id,
      readers: visible ?
        reference.readers.filter(r => r !== '*') :
        ['*', ...reference.readers]
    })
  }

  const makeLootable = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    const data = reference.data || {}
    updateReference({
      referenceId: reference.id,
      data: {...data, lootable: true}
    })
  }

  if (!controls) {
    controls = []
    if (isGamemaster) {
      if (article.articleType === 'Item' && !reference.data?.lootable) {
        controls.push(
          <IconButton onClick={makeLootable} key='share'>
            <FiShare/>
          </IconButton>
        )
      }
      controls.push(
        <IconButton onClick={toggleVisible} key='toggleVisible'>
          {visible ? <HiEye/> : <HiEyeOff/>}
        </IconButton>
      )
      controls.push(
        <IconButton onClick={onDeleteClick} key='delete'>
          <XMarkIcon/>
        </IconButton>
      )
    }
  }

  return (
    <div className={visible ? "" : styles.notVisible}>
    <ArticleImage
      article={article}
      controls={controls.length > 0 ? (
        <div>
          {controls}
        </div>
      ) : ""}
    />
    </div>
  )
}
export default ReferenceImage
