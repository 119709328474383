import { FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {useInterval} from 'react-extensions'
import {useArticle, useCampaign} from 'queries'
import API, {MapItem} from 'api'
import {AuthContext} from 'auth'
import MapContext from './MapContext'

type Props = {
  children: ReactNode
}

const MapContextProvider: FC<Props> = ({children}) => {
  const {token, setToken} = useContext(AuthContext)
  const campaign = useCampaign()
  const article = useArticle()
  const navigate = useNavigate()
  const [mapItems, setMapItems] = useState<MapItem[]>([])

  const loadMapItems = useCallback(async () => {
    try {
      const mapItems = campaign ? await API.getMapItems(token!.idToken!, campaign!, article!) : []
      setMapItems(mapItems)
    }
    catch (e: any) {
      if (e.status === 401) {
        setToken({})
        navigate('/')
        return
      }
      console.error(e)
    }
  }, [token, campaign, article, navigate, setToken])

  useInterval(() => {
    if (!document.hidden) {
      loadMapItems()
    }
  }, 1000)

  useEffect(() => {
    loadMapItems()
  }, [article, campaign, token, loadMapItems])
  return (
    <MapContext.Provider value={{mapItems}}>
      {children}
    </MapContext.Provider>
  )
}

export default MapContextProvider
