import Routes from './Routes'
import {AuthContextProvider} from 'auth'
import {DragContextProvider} from 'drag'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()

const App = () => {
  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <DragContextProvider>
          <Routes />
        </DragContextProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  )
}

export default App
