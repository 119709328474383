import { DragEvent, FC, useContext, useState } from 'react'
import {UploadedImage} from 'app'
import {AuthContext} from 'auth'
import {DropReceiver} from 'drag'
import {Map, MapContextProvider} from 'maps'
import ArticleHeader from './ArticleHeader'
import ArticleBlockList from './ArticleBlockList'
import ArticleReferenceList from './ArticleReferenceList'
import Parchment from './Parchment'
import styles from './Article.module.scss'
import {useArticle, useCampaign, useCreateReference} from 'queries'

type Props = {
  articleId: string
}

const Article: FC<Props> = ({articleId}) => {
  const article = useArticle()
  const [lightboxShown, setLightboxShown] = useState(false)
  const campaign = useCampaign()
  const {userId} = useContext(AuthContext)
  const isGamemaster = campaign?.writers.includes(userId!)
  const {mutate: createReference} = useCreateReference()

  const internalOnDrop = async (dataTransfer: DataTransfer, e: DragEvent) => {
    const storedDrag = localStorage.getItem('drag')
    if (storedDrag) {
      const parsed = JSON.parse(storedDrag)
      if (parsed.articleId) {
        createReference({
          sourceId: article!.id,
          targetId: parsed.articleId
        })
      }
      return
    }
  }

  if (!article) {
    return <></>
  }
  return (
    <>
      {lightboxShown && <div className={styles.lightbox}>
        <div className={styles.lightboxBackdrop} onClick={() => setLightboxShown(false)}/>
        <div className={styles.lightboxContent}>
          <UploadedImage image={article!.heroImage}/>
        </div>
      </div>}
      <Parchment className={styles.parchment}>
        <div className={styles.parchmentInner}>
          <ArticleHeader
            onImageClick={() => setLightboxShown(true)}
          />
          {article!.articleType === 'Map' && <MapContextProvider><Map /></MapContextProvider>}
          <DropReceiver onDrop={internalOnDrop} disabled={!isGamemaster}>
            <ArticleReferenceList/>
          </DropReceiver>
          <ArticleBlockList/>
        </div>
      </Parchment>
    </>
  )
}

export default Article
