import { useContext } from 'react'
import {AuthContext} from 'auth'
import {useMutation} from '@tanstack/react-query'
import API, {ArticleUpdate} from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import {ArticleContext} from 'articles'

export default function useUpdateArticle() {
  const {token, setToken} = useContext(AuthContext)
  const navigate = useNavigate()
  const {campaignId} = useParams()
  const {articleId} = useContext(ArticleContext)

  return useMutation(async (update: ArticleUpdate & {articleId?: string}) => {
    if (!token?.idToken) {
      return;
    }
    try {
      await API.updateArticle(token.idToken, campaignId!, update.articleId || articleId!, update)
    }
    catch (e: any) {
      if (e.status === 401) {
        setToken({})
        navigate('/')
        return []
      }
      console.error(e)
    }
  })
}
