import {FC} from 'react'
import {MapItem as ApiMapItem} from 'api'
import MapPin from './MapPin'

type Props = {
  item: ApiMapItem,
  containerHeight: number,
  containerWidth: number
}

const MapItem: FC<Props> = ({item, containerHeight, containerWidth}) => {
  if (item.type === 'MapPin') {
    return <div style={{
      position: "absolute",
      left: `${containerWidth * item.x}px`,
      top: `${containerHeight * item.y}px`
    }}><MapPin pin={item}/></div>
  }
  console.error(`Encountered unexpected map item type: ${item.type}`)
  return <></>
}

export default MapItem
