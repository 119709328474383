import { FC, ReactNode, useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import AuthContext from './AuthContext'

type Props = {
  children: ReactNode
}

const AuthContextProvider: FC<Props> = ({children}) => {
  const [token, setToken] = useState(() => {
    const stored = localStorage.getItem("authToken");
    return stored ? JSON.parse(stored) : {}
  });
  const [userId, setUserId] = useState<string | undefined>(() => {
    if (token.idToken) {
      const decoded = jwtDecode(token.idToken) as any
      return decoded.sub
    }
    else {
      return undefined
    }
  })
  useEffect(() => {
    if (localStorage.getItem("authToken") === JSON.stringify(token)) {
      return
    }
    localStorage.setItem("authToken", JSON.stringify(token));
    if (token.idToken) {
      const decoded = jwtDecode(token.idToken) as any
      setUserId(decoded.sub)
    }
    else {
      setUserId(undefined)
    }
  }, [token]);
  return (
    <AuthContext.Provider value={{token, userId, setToken}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
