import { createContext } from 'react'

interface DragContextData {
  dragging: boolean,
  mostRecentlyDraggedOverElement: Element | null
  setMostRecentlyDraggedOverElement: (element: Element | null) => void
}

const DragContext = createContext<DragContextData>({dragging: false, mostRecentlyDraggedOverElement: null, setMostRecentlyDraggedOverElement: () => {}})
export default DragContext
