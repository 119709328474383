import {FC, ReactNode, useEffect, useRef, useState} from 'react'
import ContentEditableField from './ContentEditableField'

type Props = {
  onSubmit: (value: string) => Promise<void>,
  value: string,
  label?: string,
  children: ReactNode
  editable?: boolean
}
const HiddenInlineFieldForm: FC<Props> = (props) => {
  const ref = useRef(null)
  const editable = props.editable === undefined ? true : props.editable
  const [expanded, setExpanded] = useState(false)
  const onSubmit = async (value: string) => {
    await props.onSubmit(value)
    setExpanded(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !(ref.current as Element).contains(event.target as Node)) {
        setExpanded(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    };
  }, []);

  if (expanded && editable) {
    return <div ref={ref} className='flex-fill'>
      <ContentEditableField onSubmit={onSubmit} value={props.value} editable={true} autoFocus/>
    </div>
  }
  return <span ref={ref} onClick={() => setExpanded(true)}>{props.children}</span>
}

export default HiddenInlineFieldForm
