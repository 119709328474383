import {FC} from 'react'
import {Article} from 'api'
import { useCampaign } from 'queries'
import { Link } from 'react-router-dom'

type Props = {
  result: {item: Article}
}

const ArticleSearchResult: FC<Props> = ({result}) => {
  const campaign = useCampaign()
  const {item: article} = result
  return (
    <div className='mx-3 my-2'>
      <Link to={`/campaigns/${campaign?.id}/articles/${article.id}`}>
        {article.name}
      </Link>
    </div>
  )
}

export default ArticleSearchResult
