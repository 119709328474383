import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import API from 'api'
import {AuthContext} from 'auth'
import {useArticle, useCampaign} from 'queries'

export default function useMapApi() {
  const campaign = useCampaign()
  const article = useArticle()
  const {token, setToken} = useContext(AuthContext)
  const navigate = useNavigate()

  return {
    createMapPin: async (change: {
      x: number,
      y: number,
      pinnedArticleId: string
    }) => {
      try {
        await API.createMapPin(token.idToken!, campaign!.id, article!.id, change)
      }
      catch(e: any) {
        if (e.status === 401) {
          setToken({})
          navigate('/')
          return
        }
        console.error(e)
      }
    }
  }
}
