import { FC, ReactNode } from 'react'
import IfLoggedOut from './IfLoggedOut'

function buildCognitoUri(path: string): string {
  const domain = process.env.REACT_APP_COGNITO_DOMAIN
  const query = [
    `client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
    'response_type=token',
    'scope=email+openid+profile',
    `redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_DOMAIN}/auth/callback`
  ].join('&')
  return `${domain}${path}?${query}`
}

const loginUri = buildCognitoUri('/login')

type Props = {
  children: ReactNode,
  className?: string
}

const LogoutLink: FC<Props> = ({children, className}) => {
  return (
    <IfLoggedOut>
      <a className={className} href={loginUri}>{children}</a>
    </IfLoggedOut>
  )
}

export default LogoutLink
