import { FC } from 'react'
import { UploadedFile } from 'api'
import { useCampaign } from 'queries'

type Props = {
  image?: UploadedFile
  onClick?: () => void
}

const UploadedImage: FC<Props> = ({image, onClick}) => {
  const campaign = useCampaign()
  if (!image || !campaign) {
    return <></>
  }
  const path = `${process.env.REACT_APP_UPLOADS_DOMAIN}/campaigns/${campaign?.id}/uploads/${image.id}/${image.filename}`
  return <img src={path} alt="" onClick={onClick} />
}

export default UploadedImage
