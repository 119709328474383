import {DragEvent, FC, useContext, useRef, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/20/solid'
import API, {Article, Reference} from 'api'
import {useArticlesTree, useCampaign, useUpdateArticle} from 'queries'
import {HiddenInlineFieldForm, PositionDivider} from 'design-system'
import {AuthContext} from 'auth'
import {DragContext} from 'drag'
import {
  useQueryClient
} from '@tanstack/react-query'

type Props = {
  gt?: number,
  lt?: number,
  parent?: Article
}
const ArticleInsertionControl: FC<Props> = ({gt, lt, parent}) => {
  if (!gt) {
    gt = Number.MIN_SAFE_INTEGER
  }
  if (!lt) {
    lt = Number.MAX_SAFE_INTEGER
  }
  const scale = lt - gt
  const {token, setToken} = useContext(AuthContext)
  const campaign = useCampaign()
  const articles = useArticlesTree()
  const {mutate: updateArticle} = useUpdateArticle()
  const [name, setName] = useState("")
  const navigate = useNavigate()
  const {dragging, mostRecentlyDraggedOverElement, setMostRecentlyDraggedOverElement} = useContext(DragContext)
  const dragPreviewElement = useRef(null)
  const dragOverElement = useRef(null)
  const queryClient = useQueryClient()

  const save = async (value: string) => {
    let position = gt!
    let iterationCount = 0
    while (position === gt || position === lt || position === 0) {
      position = Math.floor(Math.random() * scale) + gt!
      iterationCount++
      if (iterationCount > 10) {
        console.error("could not save article because no available position was found")
        return;
      }
    }
    const parentReference: Reference | undefined = parent ? {
      type: "Article",
      id: parent.id
    } : undefined
    await API.createArticle(token.idToken!, campaign!.id, {
      name: value,
      position,
      parentItem: parentReference
    })
      .then((article) => {
        queryClient.invalidateQueries()
        // setExpanded(false)
        setName("")
        navigate(`/campaigns/${campaign?.id}/articles/${article.id}`)
      })
      .catch(e => {
        if (e.status === 401) {
          setToken({})
          navigate('/')
          return
        }
        console.error(e)
      })
  }
  if (gt === lt) {
    console.error(`gt ${gt} must not equal lt ${lt}`)
    return <></>
  }

  const itemBeingDragged = (): any | undefined => {
    const item = localStorage.getItem('drag')
    if (item) {
      const parsed = JSON.parse(item)
      if (parsed.articleId && (!parent || !articles?.itemIsWithin(parent.id, parsed.articleId))) {
        return parsed
      }
    }
  }

  const onDragOver = (e: DragEvent) => {
    setMostRecentlyDraggedOverElement(dragOverElement.current)
    const item = itemBeingDragged()
    if (item) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const onDrop = async (e: DragEvent) => {
    const item = itemBeingDragged()
    if (!item) {
      return
    }

    e.preventDefault()
    e.stopPropagation()
    const articleId = e.dataTransfer.getData("articleId")
    if (!articleId) {
      return
    }
    if (parent && articles?.itemIsWithin(parent.id, articleId)) {
      return
    }

    let position = gt!
    let iterationCount = 0
    while (position === gt || position === lt || position === 0) {
      position = Math.floor(Math.random() * scale) + gt!
      iterationCount++
      if (iterationCount > 10) {
        console.error("could not save article because no available position was found")
        return;
      }
    }
    const parentReference: Reference | undefined = parent ? {
      type: "Article",
      id: parent.id
    } : undefined

    await updateArticle({
      articleId: articleId,
      position,
      parentItem: parentReference || null
    })
  }

  const draggingOver = dragOverElement.current && dragging && mostRecentlyDraggedOverElement && mostRecentlyDraggedOverElement === dragOverElement.current && itemBeingDragged()

  return (
    <div onDragOver={onDragOver} onDrop={onDrop} ref={dragOverElement}>
      {draggingOver && <div ref={dragPreviewElement} dangerouslySetInnerHTML={{__html: itemBeingDragged()?.html}}/>}
      {!draggingOver && <div className='ms-2'><HiddenInlineFieldForm onSubmit={save} value={name} label='Name'>
        <PositionDivider><PlusIcon/></PositionDivider>
      </HiddenInlineFieldForm></div>}
    </div>
  )
}

export default ArticleInsertionControl
