import {useContext} from 'react'
import {v4} from 'uuid'
import { UsersIcon } from '@heroicons/react/20/solid'
import {useCampaign} from 'queries'
import {AuthContext} from 'auth'
import API from 'api'
import {
  useQueryClient
} from '@tanstack/react-query'
import styles from './MembersPage.module.scss'

const MembersPage = () => {
  const campaign = useCampaign()
  const {token} = useContext(AuthContext)
  const queryClient = useQueryClient()

  const generateInvitationCode = async () => {
    await API.updateCampaign(token.idToken!, campaign!.id, {invitationCode: v4()})
    queryClient.invalidateQueries()
  }

  const deleteInvitationCode = async () => {
    await API.updateCampaign(token.idToken!, campaign!.id, {invitationCode: null})
    queryClient.invalidateQueries()
  }
  if (campaign?.invitationCode) {
    return (
      <div className={styles.content}>
        <p>To invite players, give them this link:</p>
        <p>{process.env.REACT_APP_COGNITO_REDIRECT_DOMAIN}/invitations/{campaign?.id}/{campaign?.invitationCode}</p>
        <button className='btn btn-secondary' onClick={generateInvitationCode}>Generate New Code</button>
        <button className='btn btn-danger ms-3' onClick={deleteInvitationCode}>Disable Invitations</button>
      </div>
    )
  }
  else {
    return (
      <div>
        <button className='btn btn-primary' onClick={generateInvitationCode}><UsersIcon/> Invite Players</button>
      </div>
    )
  }
}
export default MembersPage
