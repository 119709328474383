import { useContext, useMemo } from 'react'
import {AuthContext} from 'auth'
import {
  useQuery
} from '@tanstack/react-query'
import API, {Campaign} from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import {CampaignSocketContext} from 'campaigns'

function useAPICampaign() {
  const {token, setToken} = useContext(AuthContext)
  const {campaignId} = useParams()
  const navigate = useNavigate()

  return useQuery<Campaign>({
    queryKey: ['apiCampaigns', token?.idToken],
    queryFn: async () => {
      if (!token?.idToken) {
        return []
      }
      try {
        return await API.getCampaigns(token.idToken)
      }
      catch (e: any) {
        if (e.status === 401) {
          setToken({})
          navigate('/')
          return []
        }
        console.error(e)
      }
    },
    select: (campaigns: Campaign[]) => campaigns.find(c => c.id === campaignId)
  } as any)
}

export default function useCampaign(): Campaign {
  const {campaignId} = useParams()
  const {data: apiCampaign} = useAPICampaign()
  const {latestSocketChangesByType} = useContext(CampaignSocketContext)
  const socketCampaignChange = (latestSocketChangesByType['Campaign'] || {})[campaignId || '']
  return useMemo(() => {
    if (socketCampaignChange && (!apiCampaign || Number(socketCampaignChange.version) > Number(apiCampaign.version))) {
      return socketCampaignChange.object
    }
    return apiCampaign
  }, [apiCampaign, socketCampaignChange])
}
