import { DefaultBlockSchema } from "@blocknote/core";
import { DragHandleMenuItem, DragHandleMenuProps } from "@blocknote/react";
import {EyeIcon, EyeSlashIcon} from '@heroicons/react/20/solid'
import styles from './TogglePrivacyButton.module.scss'

const TogglePrivacyButton = (
  props: DragHandleMenuProps<DefaultBlockSchema>
) => {
  const isPublic = (props.block.props.backgroundColor === '*');

  const togglePrivacy = () => {
    if (isPublic) {
      props.editor.updateBlock(
        props.block.id,
        {props: {backgroundColor: ""}}
      )
    }
    else {
      props.editor.updateBlock(
        props.block.id,
        {props: {backgroundColor: '*'}}
      )
    }
  }

  return (
    <DragHandleMenuItem
      className={styles.container}
      closeMenu={props.closeMenu}
      onClick={togglePrivacy}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: 1 }}>{isPublic ? "Hide" : "Reveal"}</div>
        {isPublic ? <EyeSlashIcon/> : <EyeIcon/>}
      </div>
    </DragHandleMenuItem>
  )
}

export default TogglePrivacyButton
