import { FC } from 'react'
import { Article } from 'api'
import { useArticlesTree, useCampaign } from 'queries'
import ArticleInsertionControl from './ArticleInsertionControl'
import ArticleListItem from './ArticleListItem'

type Props = {
  parent?: Article
}

const ArticleList: FC<Props> = ({parent}) => {
  const articleTree = useArticlesTree()
  const campaign = useCampaign()

  let position: number | undefined = undefined
  const articles = parent ? articleTree?.childrenOf(parent) : articleTree?.rootItems
  return <>
    {articles?.map((article, index) => {
      const previousPosition = position
      position = article.position
      return (
        <ArticleListItem key={article.id} article={article} previousPosition={previousPosition} parent={parent}/>
      )
    })}
    {campaign?.isGamemaster &&
      <div className='ms-3'>
        <ArticleInsertionControl gt={position} parent={parent}/>
      </div>
    }
  </>
}

export default ArticleList
