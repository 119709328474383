import {FC, ReactNode} from 'react'
import styles from './PositionDivider.module.scss'

type Props = {
  onClick?: () => void,
  children: ReactNode
}
const PositionDivider: FC<Props> = ({onClick, children}) => {
  return <div className={`${styles.divider}`} onClick={onClick}>
    <div className={styles.childrenContainer}>
      {children}
    </div>
  </div>
}

export default PositionDivider
