import { useContext } from 'react'
import {AuthContext} from 'auth'
import {useMutation} from '@tanstack/react-query'
import API from 'api'
import { useNavigate, useParams } from 'react-router-dom'

export default function useUpdateArticle() {
  const {token, setToken} = useContext(AuthContext)
  const navigate = useNavigate()
  const {campaignId} = useParams()

  return useMutation(async (deletion: {referenceId: string}) => {
    if (!token?.idToken) {
      return;
    }
    try {
      await API.deleteReference(token.idToken, campaignId!, deletion.referenceId)
    }
    catch (e: any) {
      if (e.status === 401) {
        setToken({})
        navigate('/')
        return []
      }
      console.error(e)
    }
  })
}
