import { useParams } from 'react-router-dom'
import Article from './Article'
import ArticleContextProvider from './ArticleContextProvider'

const ArticlePane = () => {
  const {articleId} = useParams()
  return (
    <>
      <ArticleContextProvider articleId={articleId!}>
        <Article articleId={articleId!}/>
      </ArticleContextProvider>
    </>
  )
}

export default ArticlePane
