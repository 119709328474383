import { useContext, useMemo } from 'react'
import {AuthContext} from 'auth'
import {
  useQuery
} from '@tanstack/react-query'
import API, {Membership} from 'api'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {CampaignSocketContext} from 'campaigns'

function useAPIMemberships() {
  const {token, setToken} = useContext(AuthContext)
  const navigate = useNavigate()
  const {campaignId} = useParams()

  return useQuery<Membership[]>({
    queryKey: ['apiMemberships', token?.idToken],
    queryFn: async () => {
      if (!token?.idToken) {
        return []
      }
      try {
        return await API.getMemberships(token?.idToken, campaignId!)
      }
      catch (e: any) {
        if (e.status === 401) {
          setToken({})
          navigate('/')
          return []
        }
        console.error(e)
      }
    }
  } as any)
}

function useLatestMemberships(): Record<string, Membership> {
  const {data: apiMemberships} = useAPIMemberships()
  const {latestSocketChangesByType} = useContext(CampaignSocketContext)
  const socketMembershipChanges = latestSocketChangesByType['Membership']
  return useMemo(() => {
    const latest: Record<string, Membership> = {}
    for (const membership of (apiMemberships || [])) {
      latest[membership.id] = membership
    }
    for (const change of Object.values(socketMembershipChanges || {})) {
      const apiMatch = latest[change.id]
      if (!apiMatch || Number(change.version) > Number(apiMatch.version)) {
        latest[change.id] = change.object
      }
    }
    return latest
  }, [apiMemberships, socketMembershipChanges])
}

export default function useMemberships(): Membership[] {
  const latest = useLatestMemberships()
  return useMemo(() => {
    return Object.values(latest)
  }, [latest])
}
