import {FC, ReactNode} from 'react'
import styles from './NarrowPage.module.css'

type Props = {
  children: ReactNode
}

const NarrowPage: FC<Props> = ({children}) => {
  return (
    <div className={styles.narrowPage}>
      {children}
    </div>
  )
}

export default NarrowPage
