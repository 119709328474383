import { useContext } from 'react'
import {Article} from 'api'
import {ArticleContext} from 'articles'
import {useArticlesTree} from './useArticles'

export default function useBreadcrumbArticles(): Article[] {
  const tree = useArticlesTree()
  const {articleId} = useContext(ArticleContext)

  return tree.getBreadcrumbs(articleId!)
}
