import { FormEvent, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {AuthContext} from 'auth'
import { NarrowPage } from 'design-system'
import API from 'api'
import {useQueryClient} from '@tanstack/react-query'
import {Link} from 'react-router-dom'

const NewCampaignPage = () => {
  const {token, setToken} = useContext(AuthContext);
  const [name, setName] = useState("")
  const [username, setUsername] = useState("")
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const save = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (name.length > 0) {
      API.createCampaign(token.idToken!, {name, username})
        .then(campaign => {
          queryClient.invalidateQueries()
          navigate(`/campaigns/${campaign.id}/articles`)
        })
        .catch(e => {
          if (e.status === 401) {
            setToken({})
            navigate('/')
            return
          }
          console.error(e)
        })
    }
  }
  const change = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    setName(target.value.trim())
  }
  const changeUsername = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    setUsername(target.value.trim())
  }
  return (
    <NarrowPage>
      <h1>Start a new Campaign</h1>
      <form onSubmit={save}>
        <div className='mb-3 d-flex'>
          <label className='col-form-label me-3'>Name</label>
          <input
            type='text'
            className='form-control'
            onChange={change}
          />
        </div>
        <p>Provide a name the players can call you.</p>
        <div className='mb-3 d-flex'>
          <label className='col-form-label me-3 text-nowrap'>Your Name</label>
          <input
            type='text'
            className='form-control'
            onChange={changeUsername}
          />
        </div>
        <div className='mb-3'>
          <button type='submit' className='btn btn-primary me-3'>Save</button>
          <Link to='/' className='btn btn-secondary'>Cancel</Link>
        </div>
      </form>
    </NarrowPage>
  )
}

export default NewCampaignPage
