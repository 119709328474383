import {FC, ReactNode, useContext} from 'react'
import {Link} from 'react-router-dom'
import {ChevronRightIcon} from '@heroicons/react/20/solid'
import {ContentEditableField, IconButton} from 'design-system'
import API, {BodyBlock} from 'api'
import {AuthContext} from 'auth'
import {useArticle, useBreadcrumbArticles, useCampaign} from 'queries'
import {ImageDropReceiver} from 'drag'
import PrivacyControl from './PrivacyControl'
import ArticlePinControl from './ArticlePinControl'
import ArticleTypeControl from './ArticleTypeControl'
import {useUpdateArticle} from 'queries'
import styles from './ArticleHeader.module.scss'
import { HiX } from "react-icons/hi";


type Props = {
  controls?: ReactNode
  onImageClick?: () => void
}

function blocksToCharacters(blocks: BodyBlock[]): string {
  return blocks.map(b => {
    const content = b.content.map(c => c.text).join("")
    const children = blocksToCharacters(b.children || [])
    return [content, children].join(" ")
  }).join(" ")
}

const ArticleHeader: FC<Props> = ({controls, onImageClick}) => {
  const article = useArticle()
  const breadcrumbArticles = useBreadcrumbArticles()
  const campaign = useCampaign()
  const {token, userId} = useContext(AuthContext)
  const isGamemaster = campaign?.writers.includes(userId!)
  const articleWriters = article?.writers || []
  const canEditArticle = isGamemaster || articleWriters.includes(userId!) || articleWriters.includes('*')
  const rawContent = blocksToCharacters(article?.bodyBlocks || [])
  const contentIsShort = (article?.bodyBlocks?.length || 0) < 5 && rawContent.length < 280
  const useProfileLayout = article?.articleType !== "Map"
  const {mutate: updateArticle} = useUpdateArticle();

  const updateName = async (value: string) => {
    updateArticle({name: value})
  }

  const updateArticleType = async (value: string) => {
    updateArticle({articleType: value})
  }

  const uploadImage = async (image: File) => {
    if (!image.type.match(/^image/)) {
      console.log('Only images can be uploaded')
      return;
    }
    const upload = await API.upload(token.idToken!, campaign!.id, image)
    updateArticle({heroImage: {
      id: upload.id,
      filename: upload.filename
    }})
  }

  const uploadImages = async (images: File[]) => {
    if (images.length > 1) {
      alert('Please drop only one image here.')
      return
    }
    if (images.length < 1) {
      return
    }
    await uploadImage(images[0])
  }

  const updatePrivacy = (readers: string[], writers: string[]) => {
    updateArticle({readers, writers})
  }

  return (
    <div className={contentIsShort ? styles.useInlineProfileImage : ''}>
      {useProfileLayout &&
        <ImageDropReceiver
          className={`${styles.profileImage} ${styles.floatedProfileImage}`}
          onDrop={uploadImages}
          image={article!.heroImage}
          disabled={!canEditArticle}
          onImageClick={onImageClick}
        />
      }
      {controls || (
        <div className={styles.controls}>
          {canEditArticle && (
            <PrivacyControl
              readers={article?.readers || []}
              writers={article?.writers || []}
              inheritedReaders={campaign?.writers || []}
              inheritedWriters={campaign?.writers || []}
              onChange={updatePrivacy}
              userId={userId!}
            />
          )}
          {article && isGamemaster && <ArticlePinControl article={article}/>}
          <Link to={`/campaigns/${campaign?.id}/articles`}><HiX/></Link>
        </div>
      )}
      <div className={styles.mainHeader}>
        <div>
          {breadcrumbArticles?.map(article => {
            return (<span key={article!.id}>
              <Link to={`/campaigns/${campaign?.id}/articles/${article.id}`}>
                {article.name}
              </Link>
              <IconButton><ChevronRightIcon/></IconButton>
            </span>)
          })}
        </div>
        <div className='d-flex'>
          <div className='mt-2 me-1'>
            {article && <ArticleTypeControl
              type={article.articleType}
              onChange={updateArticleType}
              disabled={!canEditArticle}
            />}
          </div>
          <h2>
            <ContentEditableField onSubmit={updateName} value={article!.name} editable={canEditArticle}/>
          </h2>
        </div>
      </div>
      {useProfileLayout &&
        <ImageDropReceiver
          className={`${styles.profileImage} ${styles.inlineProfileImage}`}
          onDrop={uploadImages}
          image={article!.heroImage}
          disabled={!canEditArticle}
          onImageClick={onImageClick}
        />
      }
    </div>
  )
}
export default ArticleHeader
