import { FC, ReactNode, useMemo } from 'react'
import styles from './Parchment.module.scss'

type Props = {
  children: ReactNode
  className?: string
}

const Parchment: FC<Props> = ({children, className}) => {
  const seed = useMemo(() => Math.floor(Math.random() * 1000000), [])
  return (
    <>
      <div className={`${className} ${styles.parchmentContainer}`}>
        <div className={styles.parchment} style={{filter: `url('#wavy${seed}')`}}/>
        <div className={styles.parchmentContent}>
          {children}
        </div>
      </div>
      <div className='d-none'>
        <svg>
          <filter id={`wavy${seed}`}>
            <feTurbulence x="0" y="0" baseFrequency="0.02" numOctaves="5" seed={seed} />
            <feDisplacementMap in="SourceGraphic" scale="20" />
          </filter>
        </svg>
      </div>
    </>
  )
}

export default Parchment
