import React from 'react'
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom"
import {AuthCallback} from "auth"
import HomePage from "./HomePage"
import { CampaignPage, NewCampaignPage, MembersPage, InvitationPage } from 'campaigns'
import { ArticleSidebarLayout, ArticlePane } from 'articles'
import Layout from "./Layout"

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="invitations/:campaignId/:invitationCode" element={<InvitationPage />} />
          <Route path="campaigns/new" element={<NewCampaignPage />} />
          <Route path="campaigns" element={<Navigate to="/" />} />
          <Route path="campaigns/:campaignId" element={<CampaignPage />}>
            <Route path="members" element={<MembersPage />}/>
            <Route index element={<ArticleSidebarLayout />}/>
            <Route path="articles" element={<ArticleSidebarLayout />}>
              <Route path=":articleId" element={<ArticlePane />}/>
            </Route>
          </Route>
          <Route path="auth/callback" element={<AuthCallback />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default RoutesComponent
