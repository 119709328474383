import {FC, useContext, useEffect, useState} from 'react'
import {useArticle, useCampaign, useUpdateArticle} from 'queries'
import {
  Block,
  BlockNoteEditor,
  DefaultBlockSchema
} from "@blocknote/core"
import TogglePrivacyButton from './TogglePrivacyButton'
import {
  BlockColorsButton,
  BlockNoteView,
  BlockTypeDropdown,
  ColorStyleButton,
  CreateLinkButton,
  DragHandleMenu,
  DragHandleMenuProps,
  NestBlockButton,
  UnnestBlockButton,
  RemoveBlockButton,
  TextAlignButton,
  ToggledStyleButton,
  Toolbar,
  useBlockNote
} from "@blocknote/react"
import "@blocknote/core/style.css"
import styles from './ArticleBlockList.module.scss'
import {v4} from 'uuid'
import {AuthContext} from 'auth'
import _ from 'lodash'
import {PrivacyFormattingToolbarButton} from './PrivacyFormattingToolbarButton'

const ArticleDragHandleMenu = (props: DragHandleMenuProps<DefaultBlockSchema>) => (
  <DragHandleMenu>
    <RemoveBlockButton {...props}>Delete</RemoveBlockButton>
    <BlockColorsButton {...props}>Colors</BlockColorsButton>
    <TogglePrivacyButton {...props}/>
  </DragHandleMenu>
)

const CustomFormattingToolbar = (props: { editor: BlockNoteEditor }) => {
  return (
    <Toolbar>
      <BlockTypeDropdown {...props} />

      <ToggledStyleButton editor={props.editor} toggledStyle={"bold"} />
      <ToggledStyleButton editor={props.editor} toggledStyle={"italic"} />
      <ToggledStyleButton editor={props.editor} toggledStyle={"underline"} />
      <ToggledStyleButton editor={props.editor} toggledStyle={"strike"} />

      <TextAlignButton editor={props.editor as any} textAlignment={"left"} />
      <TextAlignButton editor={props.editor as any} textAlignment={"center"} />
      <TextAlignButton editor={props.editor as any} textAlignment={"right"} />

      <ColorStyleButton editor={props.editor} />

      <NestBlockButton editor={props.editor} />
      <UnnestBlockButton editor={props.editor} />

      <CreateLinkButton editor={props.editor} />

      <PrivacyFormattingToolbarButton editor={props.editor} backgroundColor='*'/>
      <PrivacyFormattingToolbarButton editor={props.editor} backgroundColor='default'/>
    </Toolbar>
  );
};

function convertFromAPIBlocks(blocks: any[]): Block<DefaultBlockSchema>[] {
  return (blocks || []).map(block => {
    const {readers, content, ...otherBlockAttrs} = block
    return {
      ...otherBlockAttrs,
      props: {
        ...block.props,
        backgroundColor: readers?.length > 0 ? readers.join(",") : 'default'
      },
      content: content.map((c: any) => {
        if (c.type === 'text' && c.text.length === 0) {
          return {...c, text: ' '}
        }
        return c
      }),
      children: convertFromAPIBlocks(block.children)
    }
  })
}

function convertToAPIBlocks(blocks: Block<DefaultBlockSchema>[]): any[] {
  return blocks.map(block => {
    const {backgroundColor, ...otherProps} = block.props
    return {
      ...block,
      props: {
        ...otherProps
      },
      readers: (backgroundColor || '').split(',').filter(i => i !=='default' && i.length > 0),
      children: convertToAPIBlocks(block.children)
    }
  })
}

function getValidBlocks(editor: BlockNoteEditor<DefaultBlockSchema>): any[] {
  let tlb = editor.topLevelBlocks
  if (tlb.length === 1 && tlb[0].content.length === 0 && tlb[0].children.length === 0) {
    return [];
  }
  const last = tlb[tlb.length-1]
  if (last.content.length === 0 && last.children.length === 0) {
    tlb = tlb.slice(0, -1)
  }

  return tlb.map(b => {
    return {
      ...b,
      props: {...b.props}
    }
  })
}

type Props = {}

const ArticleBlockList: FC<Props> = () => {
  const article = useArticle()
  const campaign = useCampaign()
  const {userId} = useContext(AuthContext)
  const {mutate: updateArticle} = useUpdateArticle()
  const blocks = article?.bodyBlocks ? convertFromAPIBlocks(article.bodyBlocks) : []
  const [externallySourcedBlocks, setExternallySourcedBlocks] = useState(blocks)
  const [editorId, setEditorId] = useState(v4())
  const editable = campaign?.writers.includes(userId!) || article?.writers.includes(userId!) || article!.writers.includes('*')
  const editor = useBlockNote<DefaultBlockSchema>(
    {
      editable,
      customElements: {
        dragHandleMenu: ArticleDragHandleMenu,
        formattingToolbar: CustomFormattingToolbar
      },
      initialContent: externallySourcedBlocks,
      onEditorContentChange: (editor) => {
        setTimeout(() => {
          const apiBlocks = getValidBlocks(editor)
          if (apiBlocks.length === 0) {
            return
          }
          if (!_.isEqual(apiBlocks, blocks)) {
            updateArticle({bodyBlocks: convertToAPIBlocks(apiBlocks), bodyUpdatedByComponent: editorId})
          }
        }, 0)
      }
    },
    [JSON.stringify(externallySourcedBlocks), editorId, editable]
  )

  useEffect(() => {
    setEditorId(v4())
  }, [article?.id])

  const blocksJson = JSON.stringify(blocks)
  useEffect(() => {
    if (editor && article?.bodyUpdatedByComponent !== editorId) {
      const validBlocks = convertToAPIBlocks(getValidBlocks(editor))
      if (JSON.stringify(validBlocks) !== blocksJson) {
        setExternallySourcedBlocks(JSON.parse(blocksJson))
      }
    }
  }, [editor, blocksJson, article?.bodyUpdatedByComponent, editorId])

  return <div className={styles.list}><BlockNoteView editor={editor} /></div>
}

export default ArticleBlockList
