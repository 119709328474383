import { createContext } from 'react'
import { MapItem } from 'api'

export type MapContextData = {
  mapItems: MapItem[]
}

const MapContext = createContext<MapContextData>({
  mapItems: []
})
export default MapContext
