import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from './AuthContext'

const AuthCallback = () => {
  const hash = window.location.hash.split('#')[1]
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate()

  useEffect(() => {
    const hashObject: {[key: string]: any} = {}
    const hashPieces = hash.split('&')
    for (const piece of hashPieces) {
      const [k, v] = piece.split('=')
      hashObject[k] = v
    }
    setToken({
      accessToken: hashObject.access_token,
      idToken: hashObject.id_token,
      expiresIn: hashObject.expires_in,
      tokenType: hashObject.token_type
    })
    navigate('/')
  }, [hash, navigate, setToken])
  return (
    <></>
  )
}

export default AuthCallback
