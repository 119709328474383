import { createContext } from 'react'

export type Change<T> = {
  id: string
  objectType: string
  version: number
  object: T
}

export type ChangesByType = Record<string, Record<string, Change<any>>>

export type CampaignSocketContextData = {
  latestSocketChangesByType: ChangesByType
}

const CampaignSocketContext = createContext<CampaignSocketContextData>({
  latestSocketChangesByType: {}
})
export default CampaignSocketContext
