import {FC, MouseEvent, useContext, useState} from 'react'
import {XMarkIcon } from '@heroicons/react/20/solid'
import {FaHandHoldingHeart} from 'react-icons/fa'
import {Article, ObjectReference} from 'api'
import {AuthContext} from 'auth'
import {IconButton} from 'design-system'
import {
  useCampaign,
  useCreateReference,
  useDeleteReference,
  usePinnedArticles,
  useUpdateReference
} from 'queries'
import ReferenceImage from './ReferenceImage'
import styles from './LootableReferenceImage.module.scss'

type Props = {
  reference: ObjectReference
}

const LootableReferenceImage: FC<Props> = ({reference}) => {
  const campaign = useCampaign()
  const pinnedArticles = usePinnedArticles()
  const [giveVisible, setGiveVisible] = useState(false)
  const {userId} = useContext(AuthContext)
  const {mutate: updateReference} = useUpdateReference();
  const isGamemaster = campaign?.writers.includes(userId!)
  const controls = []
  const playerCharacters = pinnedArticles?.filter(pa => pa.articleType === 'Player Character')
  const {mutate: createReference} = useCreateReference()
  const {mutate: deleteReference} = useDeleteReference()

  const onUnlootClick = async (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    const data = reference.data || {}
    updateReference({
      referenceId: reference.id,
      data: {...data, lootable: false}
    })
  }

  const onGiveClick = async (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setGiveVisible(!giveVisible)
  }

  const giveTo = async(pc: Article) => {
    createReference({
      sourceId: pc.id,
      targetId: reference.targetId,
      readers: ['*']
    })
    deleteReference({referenceId: reference.id})
  }

  if (isGamemaster) {
    if (giveVisible) {
      controls.push(
        <div onClick={onGiveClick} className={styles.giveMenu}>
          Give to:
          {playerCharacters.map(pc =>
            <div onClick={() => giveTo(pc)}>{pc.name}</div>
          )}
        </div>
      )
    }
    controls.push(
      <IconButton onClick={onGiveClick} key='give'>
        <FaHandHoldingHeart/>
      </IconButton>
    )
    controls.push(
      <IconButton onClick={onUnlootClick} key='unloot'>
        <XMarkIcon/>
      </IconButton>
    )
  }
  return (
    <ReferenceImage
      reference={reference}
      controls={controls}
    />
  )
}
export default LootableReferenceImage
