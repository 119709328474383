import { createContext } from 'react'

interface Token {
  accessToken?: string
  idToken?: string
  expiresIn?: number
  tokenType?: string
}

interface SettableToken {
  token: Token,
  setToken: (token: Token) => void
  userId?: string
}

const TokenContext = createContext<SettableToken>({token: {}, setToken: () => {}})
export default TokenContext
