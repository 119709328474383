import {FC, FocusEvent, FormEvent, KeyboardEvent, useEffect, useRef, useState} from 'react'

type Props = {
  onSubmit: (value: string) => Promise<void>,
  onBackspaceAtBeginning?: (value: string) => Promise<void>,
  value?: string,
  label?: string,
  editable?: boolean,
  className?: string,
  autoFocus?: boolean
}
const ContentEditableField: FC<Props> = (props) => {
  const editable = props.editable === undefined ? true : props.editable
  const [value, setValue] = useState<string>(props.value || "")
  const editableElement = useRef(null)

  useEffect(() => {
    if (props.autoFocus) {
      (editableElement.current as any | null)?.focus();
    }
  }, [props.autoFocus, editableElement]);

  const submit = (target: Element, value: string) => {
    if (value.trim().length === 0) {
      target.innerHTML = props.value || ""
      return
    }
    if (value !== props.value) {
      props.onSubmit(value)
    }
  }
  const keyDown = (event: KeyboardEvent) => {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      (event.currentTarget as any).blur()
    }
    if (event.code === 'Backspace') {
      const sel = window.getSelection();
      const range = sel!.getRangeAt(0);
      const caretPos = range.endOffset;
      if (caretPos === 0 && props.onBackspaceAtBeginning) {
        props.onBackspaceAtBeginning(value);
      }
    }
  }
  const keyUp = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      setValue(props.value || "")
      event.currentTarget.innerHTML = props.value || "";
      (event.currentTarget as any).blur()
    }
  }
  const onInput = (event: FormEvent) => {
    setValue(event.currentTarget.innerHTML || "")
  }
  const onBlur = (event: FocusEvent) => {
    submit(event.currentTarget, event.currentTarget.innerHTML || "")
  }
  return (
    <div
      className={props.className}
      ref={editableElement}
      contentEditable={editable}
      suppressContentEditableWarning
      // onClick={() => {if (editable) setExpanded(true)}}
      onInput={onInput}
      onBlur={onBlur}
      onKeyDown={keyDown}
      onKeyUp={keyUp}
      dangerouslySetInnerHTML={{__html: props.value || ""}}
    />
  )
}

export default ContentEditableField
