import {FC} from 'react'
import {UploadedFile} from 'api'
import {UploadedImage} from 'app'
import DropReceiver from './DropReceiver'

type Props = {
  onDrop: (files: File[]) => void,
  image?: UploadedFile,
  className?: string
  disabled?: boolean
  onImageClick?: () => void
}

const ImageDropReceiver: FC<Props> = ({image, onDrop, className, disabled, onImageClick}) => {
  const internalOnDrop = async (dataTransfer: DataTransfer) => {
    if (dataTransfer.files.length > 0) {
      onDrop([...dataTransfer.files])
      return
    }
    const url = dataTransfer.getData('URL')
    if (url) {
      const response = await fetch(url)
      if (response.body) {
        const urlSegments = (url.split('?')[0]).split('/')
        const filename = urlSegments[urlSegments.length - 1]
        const file = new File([await response.blob()], filename)
        onDrop([file])
      }
      return
    }
  }
  return <DropReceiver onDrop={internalOnDrop} className={className} disabled={disabled}>
    {image && <UploadedImage image={image} onClick={onImageClick} />}
  </DropReceiver>
}

export default ImageDropReceiver
