import {DragEvent, FC, ReactNode, useContext} from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import DragContext from './DragContext'
import styles from './DropReceiver.module.scss'

type Props = {
  children: ReactNode,
  onDrop: (dataTransfer: DataTransfer, e: DragEvent) => void,
  className?: string,
  disabled?: boolean
}

const DropReceiver: FC<Props> = ({children, onDrop, className, disabled}) => {
  const {dragging} = useContext(DragContext)

  const internalOnDrop = (e: DragEvent) => {
    if (disabled) {
      return
    }
    e.preventDefault()
    e.stopPropagation()
    onDrop(e.dataTransfer, e)
  }
  const onDragOver = (e: DragEvent) => {
    if (disabled) {
      return
    }
    e.preventDefault()
  }
  const showIndicator = !disabled && (dragging || !children)

  return (
    <div className={`${styles.dropReceiver} ${showIndicator ? styles.withIndicator : ''} ${className}`} onDragOver={onDragOver} onDrop={internalOnDrop}>
      {showIndicator && (
        <div className={styles.dropReceiverIndicator}>
          <PlusIcon/>
          <div>Drop Image Here</div>
        </div>
      )}
      {children}
    </div>
  )
}

export default DropReceiver
