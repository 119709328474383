import {useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UsersIcon } from '@heroicons/react/20/solid'
import {AuthContext} from 'auth'
import API from 'api'
import {ContentEditableField, IconButton} from 'design-system'
import {
  useQueryClient
} from '@tanstack/react-query'
import {useCampaign} from 'queries'
import styles from './CampaignPageHeader.module.scss'

const CampaignPageHeader = () => {
  const {token, setToken} = useContext(AuthContext)
  const campaign = useCampaign()
  const name = campaign?.name || ""
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const save = async (value: string) => {
    await API.updateCampaign(token.idToken!, campaign!.id, {name: value})
      .then((campaign) => {
        queryClient.invalidateQueries()
      })
      .catch(e => {
        if (e.status === 401) {
          setToken({})
          navigate('/')
          return
        }
        console.error(e)
      })
  }
  return (
    <div className={styles.container}>
      <h1 className='d-flex'>
        <ContentEditableField onSubmit={save} value={name} editable={campaign?.isGamemaster} className='flex-fill'/>
        {campaign?.isGamemaster &&
          <Link to={`/campaigns/${campaign?.id}/members`}><IconButton><UsersIcon /></IconButton></Link>
        }
      </h1>
    </div>
  )
}

export default CampaignPageHeader
