import {useState} from 'react'
import {IfLoggedIn, LogoutLink} from "auth"
import styles from './PlotWeaveMenu.module.scss'
import {Link} from 'react-router-dom'

const PlotWeaveMenu = () => {
  const [expanded, setExpanded] = useState(false)
  return (
    <IfLoggedIn>
      <div className={styles.container}>
        <button onClick={() => {setExpanded(!expanded)}} className={styles.logoButton}>
          <img src="/logo54square.png" alt="Plot Weave menu"/>
        </button>
        {expanded && (
          <div className={styles.menu}>
            <div><Link to='/'>Home</Link></div>
            <div><LogoutLink>Log Out</LogoutLink></div>
          </div>
        )}
      </div>
    </IfLoggedIn>
  )
}

export default PlotWeaveMenu
