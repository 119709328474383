import {FC, MouseEvent, ReactNode} from 'react'
import styles from './IconButton.module.scss'

type Props = {
  onClick?: (e: MouseEvent) => void,
  muted?: boolean,
  children: ReactNode,
  type?: "button" | "submit" | "reset",
  className?: string
  enabled?: boolean
  showOnHover?: boolean
}

const IconButton: FC<Props> = ({onClick, muted, showOnHover, children, type, className, enabled}) => {
  enabled = enabled === undefined ? true : enabled
  return (
    <button type={type} className={`btn ${styles.iconButton} ${muted ? 'opacity-50' : ''} ${showOnHover ? styles.showOnHover : ''} ${className}`} onClick={onClick} disabled={!enabled}>
      {children}
    </button>
  )
}

export default IconButton
