import {ReactNode, useContext, useEffect, useMemo, useState} from 'react'
import { useParams } from 'react-router-dom'
import useWebSocket from 'react-use-websocket'
import {AuthContext} from 'auth'
import CampaignSocketContext, {Change, ChangesByType} from './CampaignSocketContext'

function addToLatest(latestSocketChangesByType: ChangesByType, message: Change<any>) {
  const typeData = latestSocketChangesByType[message.objectType] || {}
  const previousValue = typeData[message.id]
  if (Number(previousValue?.version) >= Number(message.version)) {
    return latestSocketChangesByType
  }

  return {
    ...latestSocketChangesByType,
    [message.objectType]: {
      ...typeData,
      [message.id]: message
    }
  }
}

const CampaignSocketContextProvider = ({children}: {children: ReactNode}) => {
  const {campaignId} = useParams()
  const {userId} = useContext(AuthContext)
  const [latestSocketChangesByType, setLatestSocketChangesByType] = useState({})

  const socketUrl = useMemo(() => {
    return `${process.env.REACT_APP_WS_DOMAIN}?campaignId=${campaignId}&userId=${userId}`
  }, [campaignId, userId])
  const { lastMessage } = useWebSocket(socketUrl);

  useEffect(() => {
    setLatestSocketChangesByType({})
  }, [campaignId])

  useEffect(() => {
    if (lastMessage !== null) {
      setLatestSocketChangesByType(l => addToLatest(
        l,
        JSON.parse(lastMessage.data)
      ))
    }
  }, [lastMessage]);
  return (
    <CampaignSocketContext.Provider value={{latestSocketChangesByType}}>
      {children}
    </CampaignSocketContext.Provider>
  )
}

export default CampaignSocketContextProvider
