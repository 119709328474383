import {FC, ReactNode, useEffect, useState} from 'react'
import styles from './SidebarLayout.module.scss'
import {Bars3Icon} from '@heroicons/react/20/solid'
import {IconButton} from 'design-system'

type Props = {
  sidebar: ReactNode
  body: ReactNode
  expansionMemo?: string
}

const SidebarLayout: FC<Props> = ({sidebar, body, expansionMemo}) => {
  const [expanded, setExpanded] = useState(false)
  useEffect(() => {
    if (expansionMemo) {
      setExpanded(false)
    }
  }, [expansionMemo])
  return (
    <div className={styles.layout}>
      <div className={`${styles.sidebar} ${expanded ? '' : 'hidden'}`}>
        {sidebar}
      </div>
      <div className={styles.bodyWithoutSidebar}>
        <IconButton className={`${styles.toggle} ${expanded ? styles.toggleExpanded : ''}`} onClick={() => {setExpanded(!expanded)}}><Bars3Icon/></IconButton>
        {body}
      </div>
    </div>
  )
}

export default SidebarLayout
