import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {UploadedImage} from 'app'
import {Article} from 'api'
import {useArticlesMap, useCampaign, usePinnedArticles, useReferencesFrom} from 'queries'
import styles from './PinnedScene.module.scss'
import {ArticleContextProvider, LootableReferenceImage, PinnedArticleImage, ReferenceImage} from 'articles'

const PinnedScene = () => {
  const campaign = useCampaign()

  const pinnedArticles = usePinnedArticles()
  const scene = useMemo<Article | undefined>(() => {
    return pinnedArticles.find(a => a.articleType === 'Scene')
  }, [pinnedArticles])
  const references = useReferencesFrom(scene?.id)
  const articlesMap = useArticlesMap()

  const displayedReferences = references.filter(r => {
    const article = articlesMap[r.targetId]
    if (article.articleType === 'Item' && r.data?.lootable) {
      return false
    }
    return true
  })

  const lootableReferences = references.filter(r => {
    const article = articlesMap[r.targetId]
    return (article.articleType === 'Item' && r.data?.lootable)
  })

  if (!scene) {
    return <></>
  }
  return (
    <div className={styles.container}>
      <div className={styles.sceneImage}>
        <UploadedImage image={scene!.heroImage} />
      </div>
      <div className={styles.headerContainer}>
        <h2><Link to={`/campaigns/${campaign?.id}/articles/${scene.id}`}>{scene!.name}</Link></h2>
      </div>
      <div className={styles.playerCharactersContainer}>
        <div className='d-flex overflow-scroll justify-content-center'>
          {pinnedArticles?.filter(pa => pa.articleType === 'Player Character').map(a =>
            <ArticleContextProvider articleId={a.id} key={a.id}>
              <PinnedArticleImage article={a} />
            </ArticleContextProvider>
          )}
        </div>
      </div>
      {lootableReferences.length > 0 &&
        <div className={styles.lootBox}>
          <div className={styles.lootBoxContents}>
            {lootableReferences.map(r =>
              <div className={styles.lootableItem} key={r.id}>
                <LootableReferenceImage reference={r}/>
              </div>
            )}
          </div>
        </div>
      }
      <div className={styles.referencesContainer}>
        <div className='d-flex overflow-scroll justify-content-center'>
          {displayedReferences.map(r =>
            <ReferenceImage reference={r} key={r.id}/>
          )}
        </div>
      </div>
    </div>
  )
}

export default PinnedScene
