import { FC, MouseEvent, useContext } from 'react'
import {Article} from 'api'
import {XMarkIcon } from '@heroicons/react/20/solid'
import {IconButton} from 'design-system'
import { useCampaign, useUpdateArticle } from 'queries'
import {AuthContext} from 'auth'
import ArticleImage from './ArticleImage'

type Props = {
  article: Article
}

const ArticlePin: FC<Props> = ({article}) => {
  const campaign = useCampaign()
  const {mutate: updateArticle} = useUpdateArticle()
  const {userId} = useContext(AuthContext)
  const isGamemaster = campaign?.writers.includes(userId!)

  const unpinArticle = async (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    await updateArticle({pinned: false})
  }

  return (
    <ArticleImage
      article={article!}
      controls={isGamemaster ? (
        <IconButton onClick={unpinArticle}><XMarkIcon/></IconButton>
      ) : " "}
    />
  )
}

export default ArticlePin
